import {
  SECTION_HOMEPAGE,
  SECTION_CATEGORY,
  SECTION_POST_LIST,
  SECTION_MY_POSTS,
  getBundleName,
  SECTION_RELATED_POSTS,
} from '@wix/communities-blog-client-common';
import { MY_POSTS_PAGE_BUNDLE_NAME } from '../../my-posts-page/constants/my-posts-page';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../../post-list-widget/constants/post-list-widget';
import { RELATED_POSTS_WIDGET_BUNDLE_NAME } from '../../related-posts-widget/constants/related-posts-widget';
import { getRoute } from '../router/router-selectors';
import {
  isInFeed,
  isInCategory,
  isInTags,
  isInArchivePage,
  isInHashtagPage,
} from '../services/detect-route';

export const getSection = (state, fallbackEnabled) => {
  const route = getRoute(state);
  if (isInFeed(route)) {
    return SECTION_HOMEPAGE;
  }

  if (
    isInCategory(route) ||
    isInTags(route) ||
    isInArchivePage(route) ||
    isInHashtagPage(route)
  ) {
    return SECTION_CATEGORY;
  }

  if (getBundleName(state) === POST_LIST_WIDGET_BUNDLE_NAME) {
    return SECTION_POST_LIST;
  }

  if (getBundleName(state) === RELATED_POSTS_WIDGET_BUNDLE_NAME) {
    return SECTION_RELATED_POSTS;
  }

  if (getBundleName(state) === MY_POSTS_PAGE_BUNDLE_NAME) {
    return SECTION_MY_POSTS;
  }

  return fallbackEnabled ? SECTION_CATEGORY : undefined;
};
